module controllers {
    export module lc {
        interface ILetterOfCreditCreateScope extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }
        interface ILetterOfCreditCreateParams extends ng.ui.IStateParamsService {
            locId: number;
            ownerEntityId: number;
        }

        export class letterOfCreditCreateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                '$stateParams',
                'bsLoadingOverlayService',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'classificationValueService',
                'documentRepositoryService',
                'lookupService',
                "supplierService",
                "localeService",
                'letterOfCreditService',
                "customerService",
                "counterService",
                'currencyService',
                'incotermService',
            ];

            locId: number = 0;
                        
            customLCApplicantRef: string = "";
            
            poId: number;

            breadCrumbDesc: string;

            isLoadingDetail: boolean = false;

            isCustomLCApplicantRef: boolean = false;

            disableLetterOfCreditTab: boolean = false;
            disablePurchaseOrder: boolean = false;

            selectedTab: number = 0;

            entityList: ng.resource.IResourceArray<interfaces.applicationcore.IEntity>;
            IsDisabled: boolean = false;

            ShowPurchaseOrderGrid: boolean = false;
            ownerEntityId: number = 0;
            //Supplier Variables
            supplierId: number = 0;
            supplierModel: interfaces.applicationcore.IDropdownModel;
            supplierList: Array<interfaces.applicationcore.IDropdownModel>;
            //Currency Variables
            currencyId: number = 0;
            currencyList: Array<interfaces.applicationcore.IDropdownModel>;
            currencyModel: interfaces.applicationcore.IDropdownModel;
            //Incoterm Variables
            incotermId: number = 0;
            incotermList: Array<interfaces.applicationcore.IDropdownModel>;
            incotermModel: interfaces.applicationcore.IDropdownModel;
            //CreditType Variables
            creditTypeId: number = 0;
            creditTypeList: Array<interfaces.applicationcore.IDropdownModel>;
            creditTypeModel: interfaces.applicationcore.IDropdownModel;

            lcCounterId: number = 0;
            lcCounterList: Array<interfaces.applicationcore.IDropdownModel>;
            lcCounterModel: interfaces.applicationcore.IDropdownModel;
            
            loadPromises: ng.IPromise<any>[];
            NextStepButton: string = "Next";
            DisableNextButton: boolean = false;
            IsLoading: boolean = false;
            IsValid: boolean = false;
            apiPOList: uiGrid.IGridApi;
            selectedInv: uiGrid.IGridRow;
            purchaseOrderIdList: interfaces.lc.ILCPurchaseOrder[] = [];
            constructor(
                private $scope: ILetterOfCreditCreateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private $stateParams: ILetterOfCreditCreateParams,
                private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public lookupService: interfaces.applicationcore.ILookupService,
                private supplierService: interfaces.master.ISupplierService,
                private localeService: interfaces.applicationcore.ILocaleService,
                private letterOfCreditService: interfaces.lc.ILetterOfCreditService,
                private customerService: interfaces.master.ICustomerService,
                private counterService: interfaces.master.ICounterService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                public incotermService: services.applicationcore.incotermService,
            ) {

                this.locId = $stateParams.locId;
                this.ownerEntityId = $stateParams.ownerEntityId;
                this.supplierId = $stateParams.supplierId;
                this.currencyId = $stateParams.currencyId;
                this.creditTypeId = $stateParams.creditTypeId;
                this.incotermId = $stateParams.incotermId;
                if (this.locId === 0)
                {
                    this.breadCrumbDesc = "Create LetterOfCredit";
                }
                else
                {
                    this.breadCrumbDesc = "Update Letter Of Credit Purchase Order";
                }

                if (this.locId > 0) {

                    this.disablePurchaseOrder = false;
                    this.ShowPurchaseOrderGrid = true;                    
                    this.disableLetterOfCreditTab = true;
                    this.DisableNextButton = false;
                    this.NextStepButton = "Submit";
                    this.LoadPurchaseOrders();
                    this.selectedTab = 1;
                }
                else {

                    //Get Entities Data
                    this.loadPromises = [];

                    this.loadPromises.push(this.loadEntities());
                    this.loadPromises.push(this.getCurrentEntity());


                    bsLoadingOverlayService.wrap({
                        referenceId: 'create.letterOfCredit'
                    }, () => {
                        return $q.all(this.loadPromises).then((data) => {
                            //Now we can load the rest of the Controls, after determining which is the CurrentEntity

                            this.loadControls();
                        });
                    });

                }
            }

            isFormValid(): Boolean {
                this.IsValid = false;


                return this.IsValid;
            }

            loadControls() {

                this.loadPromises = [];
                this.loadPromises.push(this.loadLetterOfCreditCounter());
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create.letterOfCredit'
                }, () => {
                    return this.$q.all(this.loadPromises).then((data) => {
                        //Now we can load the defaults AFTER all the dropdowns have been loaded


                    });
                });

            }

            //Loading Entities
            loadEntities() {

                let deferred = this.$q.defer<boolean>();

                this.entityService.getList().query((successList) => {
                    this.entityList = successList;
                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }
            ownerEntityChanged() {
                var loadPromises: ng.IPromise<any>[] = [];

                this.supplierList = [];
                this.supplierModel = undefined;
                this.supplierId = 0;

                this.currencyList = [];
                this.currencyModel = undefined;
                this.currencyId = 0;

                this.creditTypeList = [];
                this.creditTypeModel = undefined;
                this.creditTypeId = 0;

                this.incotermList = [];
                this.incotermModel = undefined;
                this.incotermId = 0;

                this.lcCounterId = 0;
                this.lcCounterModel = undefined;
                this.lcCounterList = [];

                this.isCustomLCApplicantRef = false;
                this.customLCApplicantRef = "";
                
                //loadPromises.push(this.loadSuppliers(this.$scope.s));
                loadPromises.push(this.loadCurrencies());
                loadPromises.push(this.loadCreditTypes());
                loadPromises.push(this.loadIncoterms());
                loadPromises.push(this.loadLetterOfCreditCounter());

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create.letterOfCredit'
                }, () => {
                    return this.$q.all(loadPromises);
                });
            }
            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();


                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;

                    deferred.resolve(true);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }
            loadSupplierCurrencyList() {
                return this.letterOfCreditService.getCurrencyDropdownForSupplier(this.supplierModel.Id).query({
                }, (resultList) => {
                    this.currencyList = resultList;
                }).$promise;
            }
            loadSuppliers() {
                if (this.ownerEntityId) {
                    return this.letterOfCreditService.getSupplierDropdownLists(this.ownerEntityId).query(() => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }
            
            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }
            loadCreditTypes() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.CreditType
                }, (resultList) => {
                }).$promise;
            }
            loadIncoterms() {
                return this.incotermService.getDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }

            supplierChanged(supplierModel: interfaces.applicationcore.IDropdownModel) {
                this.supplierModel = supplierModel;
                this.currencyModel = null;


                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create.letterOfCredit'
                }, () => {
                    return this.loadSupplierCurrencyList().then((data) => {
                        this.getCreateLetterOfCreditDefaults();
                    });
                });
            }

            getCreateLetterOfCreditDefaults() {

                var supplierId = this.supplierModel ? this.supplierModel.Id : 0;

                return this.letterOfCreditService.getCreateLetterOfCreditDefaults(this.ownerEntityId, supplierId).get((data: interfaces.lc.ILetterOfCreditCreateDefaults) => {
                    this.incotermModel = data.incotermModel ? data.incotermModel : undefined;
                    this.currencyModel = data.currencyModel ? data.currencyModel : undefined;
                    this.creditTypeModel = data.creditTypeModel ? data.creditTypeModel : undefined;
                    this.lcCounterModel = data.lcCounterModel ? data.lcCounterModel : undefined;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise
            }

            NextStep() {
                this.selectedTab++;

                if (this.selectedTab == 1) {

                    this.DisableNextButton = true;
                    this.NextStepButton = "Submit";
                    this.LoadPurchaseOrders();

                } else if (this.selectedTab == 2) {
                    this.SubmitLetterOfCredit();
                }
            }

           
            SubmitLetterOfCredit() {

                this.IsLoading = true;

                var purchaseOrderIdList: interfaces.lc.ILCPurchaseOrder[] = [];

                var LCPurchaseOrderLines: interfaces.lc.ILCPurchaseOrderLines = {
                    purchaseOrderIdList: []
                };

                if (this.selectedInv && this.apiPOList.selection.getSelectedRows().length > 0) {

                    this.purchaseOrderIdList = [];

                    this.purchaseOrderIdList = _.map(this.apiPOList.selection.getSelectedRows(), (o) => {
                        return {                            
                            letterOfCreditId: 0,
                            purchaseOrderId: o.poId                            
                        };
                    });
                }

                LCPurchaseOrderLines.purchaseOrderIdList = this.purchaseOrderIdList;

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create.letterOfCredit'
                }, () => {   
                       
                        if (this.locId) {
                            return this.UpdateLetterOfCreditfromPOList(LCPurchaseOrderLines);
                        }
                        else {
                            return this.CreateFromPurchaseOrder(LCPurchaseOrderLines);
                        }                    
                });
            }

            UpdateLetterOfCreditfromPOList(letterOfCreditPoLines: interfaces.lc.ILCPurchaseOrderLines) {                
                var supplierId = this.supplierModel ? this.supplierModel.Id : 0;
                var currencyId = this.currencyModel ? this.currencyModel.Id : 0;
                var incotermId = this.incotermModel ? this.incotermModel.Id : 0;
                var creditTypeId = this.creditTypeModel ? this.creditTypeModel.Id : 0;

                return this.letterOfCreditService.updateLCFromPurchaseOrderLines(
                    this.locId
                ).save(letterOfCreditPoLines, (result) => {

                    this.generalService.displayMessageHandler(result);

                    this.IsLoading = false;

                    if (!result.HasErrorMessage) {
                        this.$state.go("auth.LetterOfCredit.Update", { locId: Number(result.ID) });
                    }

                }, (reponseError) => {
                    this.generalService.displayMessageHandler(reponseError.data);

                    this.IsLoading = false;

                }).$promise;
            }

            customLCApplicantRefChanged() {
                this.customLCApplicantRef = '';
                if (this.isCustomLCApplicantRef) {
                    this.lcCounterId = 0;
                }
            }

            //Loading Consignment Counters
            loadLetterOfCreditCounter() {

                let deferred = this.$q.defer<boolean>();

                if (this.ownerEntityId) {

                    this.counterService.getDropdownList().query({
                        ownerEntityId: this.ownerEntityId,
                        counterTypeId: Enum.EnumCounterTypes.LetterOfCredit,

                    }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                            this.lcCounterList = result;

                        angular.forEach(result, (o) => {
                            if (o.Selected) {
                                this.lcCounterId = o.Id;
                            }
                        });

                        deferred.resolve(true);

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        deferred.resolve(true);
                    })
                } else {
                    deferred.resolve(true);
                }

                return deferred.promise;
            }

            CreateFromPurchaseOrder(lcPurchaseOrderLines: interfaces.lc.ILCPurchaseOrderLines) {

                var supplierId = this.supplierModel ? this.supplierModel.Id : 0;
                var currencyId = this.currencyModel ? this.currencyModel.Id : 0;
                var incotermId = this.incotermModel ? this.incotermModel.Id : 0;
                var lcCounterId = this.lcCounterModel ? this.lcCounterModel.Id : 0;
                var creditTypeId = this.creditTypeModel ? this.creditTypeModel.Id : 0;

                return this.letterOfCreditService.createLCFromPOLines(
                    this.ownerEntityId,
                    supplierId,
                    currencyId,
                    incotermId,
                    creditTypeId,
                    this.isCustomLCApplicantRef,
                    this.customLCApplicantRef ? this.customLCApplicantRef : "",
                    lcCounterId ? lcCounterId : 0

                ).save(lcPurchaseOrderLines, (result) => {

                    this.generalService.displayMessageHandler(result);

                    this.IsLoading = false;

                    if (!result.HasErrorMessage) {
                        this.$state.go("auth.LetterOfCredit.Update", { lcId: Number(result.ID) });                        
                    }

                }, (reponseError) => {
                    this.generalService.displayMessageHandler(reponseError.data);

                    this.IsLoading = false;

                }).$promise;
            }

            LoadPurchaseOrders() {
                this.ShowPurchaseOrderGrid = false;
                this.IsLoading = true;

                this.$timeout(() => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create.letterOfCredit'
                    }, () => {

                            if (!this.locId)
                            {
                                var supplierId = this.supplierModel ? this.supplierModel.Id : 0;
                                var currencyId = this.currencyModel ? this.currencyModel.Id : 0;
                                var creditTypeId = this.creditTypeModel ? this.creditTypeModel.Id : 0;
                                var incotermId = this.incotermModel ? this.incotermModel.Id : 0;
                            }
                            else
                            {
                                var supplierId = this.supplierId;
                                var currencyId = this.currencyId;
                                var creditTypeId = this.creditTypeId;
                                var incotermId = this.incotermId;
                            }
                            if (!this.locId) {
                                this.locId = 0;
                            }
                            return this.letterOfCreditService.getPurchaseOrdersForSupplier(
                                this.ownerEntityId,
                                supplierId,
                                currencyId,
                                creditTypeId,
                                incotermId,
                                this.locId

                        ).query((data) => {

                            this.gvwPurchaseOrder.data = data;
                            this.IsLoading = false;

                            for (let item of this.gvwPurchaseOrder.data) {
                                if ((item as interfaces.lc.IPurchaseOrderLinesDisplay).Selected) {
                                    this.selectedInv = item;
                                    this.$timeout(() => {
                                        this.apiPOList.selection.unSelectRow(item);
                                        this.apiPOList.selection.selectRow(item);
                                    });
                                }
                            }

                            this.DisableNextButton = false;
                            this.ShowPurchaseOrderGrid = true;

                            if (this.apiPOList.selection.getSelectedRows().length === 0) {
                                this.DisableNextButton = true;
                            }
                            else {
                                this.DisableNextButton = false;
                            }
                        },
                            (errorResponse) => {

                                this.generalService.displayMessageHandler(errorResponse.data);
                                this.IsLoading = false;
                            }).$promise;
                    });
                });
            }




            registerGridApi(gridApi: uiGrid.IGridApi) {

                this.apiPOList = gridApi;
                this.apiPOList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.selectedInv = selected;

                    this.DisableNextButton = false;

                    if (this.apiPOList.selection.getSelectedRows().length === 0) {
                        this.DisableNextButton = true;
                    }
                    else {
                        this.DisableNextButton = false;
                    }
                });

                this.$timeout(() => {
                    if (this.selectedInv)
                        this.apiPOList.selection.selectRow(this.selectedInv.entity);
                });
            }

            //Build UIGrid
            gvwPurchaseOrder: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: true,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ui-sref="auth.ConsignmentExports.Update({ conId: row.entity.Id })" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: false
                    },
                    {
                        name: "OwnerEntity",
                        displayName: "Owner Entity",
                        field: "POOwnerEntity",
                        width: 120,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`

                    },
                    {
                        name: "PONumber",
                        displayName: "Purchase Order No.",
                        field: "PONumber",
                        width: 150,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`

                    },
                    {
                        name: "PODate",
                        displayName: "Purchase Order Date",
                        field: "PODate",
                        width: 150,
                        cellFilter: "date:'yyyy/MM/dd'",
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`

                    },
                    {
                        name: "POTypeDescription",
                        displayName: "Purchase Order Type Desc",
                        field: "POTypeDescription",
                        width: 180,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`

                    },
                    {
                        name: "TermsOfPaymentDescription",
                        displayName: "Terms Of Payment",
                        field: "TermsOfPaymentDescription",
                        width: 150,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`

                    },
                    {
                        name: "StatusDescription",
                        displayName: "Status Description",
                        field: "StatusDescription",
                        width: 150,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`

                    },
                    {
                        name: "TotalForgeignCurrencyValue", 
                        displayName: "Total Foreign Currency Value",
                        field: "TotalForeignCurrencyValue",
                        cellFilter: "number: 5",
                        width: 200,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`

                    },
                    {
                        name: "Currency",
                        displayName: "Currency",
                        field: "CurrencyCode",
                        width: 100,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`

                    },
                    {
                        name: "TotalOrderQuanity",
                        displayName: "Total Order Quantity",
                        field: "TotalOrderQuantity",
                        cellFilter: "number: 2",
                        width: 150,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`

                    }]
            };
            letterOfCreditTabSelected() {
                if (this.selectedTab == 0) {
                    this.NextStepButton = "Next";
                }
                else {
                    this.NextStepButton = "Submit";
                }
            }
            PurchaseOrderTabSelected() {
                if (this.selectedTab == 0) {
                    this.NextStepButton = "Next";
                }
                else {
                    this.NextStepButton = "Submit";
                }
            }
            close() {
                if (this.locId > 0) {
                    this.$state.go("auth.LetterOfCredit.Update", { locId: Number(this.locId) });
                }
                else {
                    this.$state.go("^");
                }
            }


        };

        angular.module("app").controller("letterOfCreditCreateCtrl", controllers.lc.letterOfCreditCreateCtrl);
    }
}
